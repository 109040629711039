<template>
  <div>
    <b-form @submit.prevent="loginWithPincode">
      <div class="container d-flex align-items-start flex-column vh-100" fluid="lg">
        <div class="row mx-auto">
          <div class="col-12 pt-4">
            <h6 class="text-center">{{ $t('confirm_pincode') }}</h6>
          </div>

          <div class="col-12 pt-4 px-0 d-flex justify-content-center">
            <div style="display: flex">
              <OtpInput
                ref="otpInput"
                input-type="number"
                input-classes="otp-input"
                separator
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                :class="{ error: $v.confirm_pincode.$error }"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              />
              <div class="my-auto ml-2">
                <i
                  v-if="showPincode"
                  class="ri-eye-line ri-lg"
                  style="line-height: unset"
                  @click="showPincode = false"
                ></i>
                <i v-else class="ri-eye-off-line ri-lg" style="line-height: unset" @click="showPincode = true"></i>
              </div>
            </div>
          </div>
          <div v-if="confirm_pincode.length == 6 && !$v.confirm_pincode.sameAs" class="col-12 pt-3">
            <div class="small text-danger text-center">
              {{ $t('pincode_must_same') }}
            </div>
          </div>
          <div class="col-12 pt-5">
            <div class="small text-primary text-center">
              {{ $t('confirm_pincode_content') }}
            </div>
          </div>
        </div>

        <div class="row mt-auto mx-auto w-100 d-flex justify-content-center">
          <div class="col-6 col-md-3 mb-4">
            <b-button type="submit" pill variant="outline-primary" class="w-100" @click="goBack()">{{
              $t('previous')
            }}</b-button>
          </div>
          <div class="col-6 col-md-3 mb-4">
            <b-button pill variant="primary" class="w-100" type="submit">{{ $t('continue') }}</b-button>
          </div>
        </div>

        <loading :is-busy="isBusy"></loading>
      </div>
    </b-form>
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
import AccountService from '@/api/service/AccountService'
import { sameAs, required } from 'vuelidate/lib/validators'
import Loading from '@/components/Loading.vue'
import Cookies from 'js-cookie'
import NotificationService from '@/api/service/NotificationService'

export default {
  components: { OtpInput, Loading },
  data() {
    return {
      pincode: this.$store.getters['register/pincode'],
      confirm_pincode: '',
      isBusy: false,
      showPincode: false,
      routeName: this.$router.currentRoute.name,
    }
  },
  watch: {
    showPincode: function (val) {
      if (val) {
        const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
        inputs.forEach((i) => i.classList.remove('password-mask'))
      } else {
        const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
        inputs.forEach((i) => i.classList.add('password-mask'))
      }
    },
  },
  mounted() {
    this.$refs.otpInput.$el.children[0].setAttribute('autocomplete', 'new-password')
    const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
    inputs.forEach((i) => i.setAttribute('autocomplete', 'new-password'))
    inputs.forEach((i) => i.setAttribute('inputmode', 'numeric'))
    inputs.forEach((i) => i.classList.add('password-mask'))
  },
  created() {
    if (!this.pincode) {
      this.goBack()
    }
  },
  methods: {
    handleOnComplete(value) {
      console.log(value)
    },
    handleOnChange(value) {
      this.confirm_pincode = value.replace(/[^0-9]+/g, '')
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
    },
    async loginWithPincode() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true
        try {
          const res = await AccountService.setPassword({
            phone_number: this.$store.getters['register/phone'],
            password: this.confirm_pincode,
            forget_password: this.routeName == 'forget-password-confirm-pincode' ? true : null,
            auth_token: this.$store.getters['auth/authToken'],
          })

          this.isBusy = false

          if (res.status == 200) {
            const h = this.$createElement
            const msg = h('div', {
              domProps: {
                innerHTML: this.$t('remember_phone_pincode', {
                  phone_number: this.$store.getters['register/phone'],
                  pincode: this.confirm_pincode,
                }),
              },
            })

            const loginResponse = res.data.data
            const loanSubmitted = loginResponse.loan_application_submitted
            const loanFormState = loginResponse.loan_form_state

            this.$store.dispatch('auth/setUserId', {
              userId: loginResponse.id,
            })
            this.$store.dispatch('auth/setToken', {
              token: loginResponse.token,
            })
            this.$store.dispatch('auth/setLoanSubmitted', {
              loanSubmitted: loanSubmitted,
            })
            this.$store.dispatch('auth/setLoanFormState', {
              loanFormState: loanFormState,
            })
            this.$store.dispatch('auth/setActive', {
              active: loginResponse.active,
            })
            this.$store.dispatch('auth/setRejectCode', {
              rejectCode: loginResponse.reject_code,
            })

            this.storeOneSignalUserId()

            this.$bvModal
              .msgBoxOk([msg], {
                hideTitle: true,
                size: 'sm',
                buttonSize: 'sm',
                bodyClass: 'p-4',
                okTitle: this.$t('got_it'),
                centered: true,
                scrollable: true,
              })
              .then((value) => {
                console.log(value)
                if (loanSubmitted) {
                  this.$router.replace({ name: 'home' })
                } else {
                  if (loanFormState === null) {
                    this.$router.replace({ path: '/personal-detail' })
                  } else if (loanFormState === 'personal') {
                    this.$router.replace({ path: '/employment' })
                  } else if (loanFormState === 'employment') {
                    this.$router.replace({ path: '/financial' })
                  } else if (loanFormState === 'financial') {
                    this.$router.replace({ path: '/file-upload' })
                  } else if (loanFormState === 'documents') {
                    this.$router.replace({ path: '/interview-appointment' })
                  }
                }
              })

            // this.storeOneSignalUserId();
          }
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
    async storeOneSignalUserId() {
      this.onesignalUserId = Cookies.get('onesignal_user_id')
      if (this.onesignalUserId) {
        const res = await NotificationService.storeUserId({
          one_signal_user_id: this.onesignalUserId,
        })
        console.log(res)
      }
    },
    goBack() {
      if (this.routeName == 'forget-password-confirm-pincode') {
        this.$router.replace({ name: 'forget-password-set-pincode' })
      } else {
        this.$router.replace({ name: 'set-pincode' })
      }
    },
  },
  validations: {
    pincode: {
      required,
    },
    confirm_pincode: {
      sameAs: sameAs('pincode'),
    },
  },
}
</script>

<style>
.password-mask {
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
}
</style>
